import './PackageModal.css'
import React from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux'
import { changeActivePage, showLoading } from '../../store/actions/pageActions'
import Label from '../label/Label';

function PackageModal (props) {

    function change(menu) {
        if(props.activePage != menu) {
            props.changeMenu(menu)
            props.showLoading()
        }
    }

    function renderRibbon(available) {
        if(!available){
            return(
                <Label label="Indisponível"></Label>
            )
        }        
    }

    function renderList(itens) {
        var i = 0;
        return itens.map(item => (
            <li key={i++}>{item}</li>
        ))
    }

    return (
        <Modal {...props} size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.show} onHide={e => props.handleClose()}>
            <Modal.Header closeButton>
            <Modal.Title>
                {props.package.nome}
                <p>{props.package.descricao}</p>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3>Vacinas inclusas:</h3>
                <ul>
                    {renderList(props.package.vacinas)}
                </ul>
                <h3>Proteção contra:</h3>
                <ul>
                    {renderList(props.package.protecao)}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <a href="#/vacinas" onClick={e => change('vacinas')}>
                    <div className="modalButton">
                        <FontAwesomeIcon icon={['fas', 'syringe']} />
                        <p>TODAS VACINAS</p>
                    </div>
                </a>
                <a href="#/querovacinar" onClick={e => change('querovacinar')}>
                    <div className="modalButton">
                        <FontAwesomeIcon icon={['fas', 'thumbs-up']} />
                        <p>QUERO VACINAR</p>
                    </div>
                </a>
            </Modal.Footer>
            {renderRibbon(props.package.disponivel)}
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        showLoading() {
            const action = showLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PackageModal)