import './AccordionGroup.css'
import React from 'react'
import AccordionButton from '../accordionButton/AccordionButton'
import { Accordion } from 'react-bootstrap'

export default props => {

    const accordions = () => {
        const list = props.accordions || []
        var index = props.initIndex

        return list.map((accordion) => (
            <AccordionButton key={index++} eventKey={index++} handleSelected={props.handleSelected} groupItens={accordion.groupItens} groupName={accordion.groupName}></AccordionButton>
        ))
    }

    function renderTitle () {
        if(props.description) {
            return (
                <p>{props.description}</p>
            )
        }
    }

    return(
        <div className="accordionGroup">
           {renderTitle()}
            <Accordion>
                {accordions()}
            </Accordion>
        </div>
    )
}