import './AccordionButton.css'
import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';

export default props => {

    const renderRows = () => {
        const list = props.groupItens || []
        return list.map(item => (
            <li key={item.id} onClick={e => props.handleSelected(e, props.groupName)}>
                <span className="fa-li">
                    <FontAwesomeIcon icon={['fas', 'angle-right']} />
                </span>
                {item.name}
            </li>
        ))
    }

    function selectGroup(e, index) {
        $('.filterCard').each((i, element) => {            
            if(i === index) {
                e.target.classList.toggle('active')
            } else {
                element.classList.remove('active')
            }
        })        
    } 

    return(
        <Card>
            <Accordion.Toggle className="filterCard" as={Card.Header} eventKey={props.eventKey+""} onClick={e => selectGroup(e, props.eventKey)}>
                {props.groupName}
                <div className="accordionButton">
                    <FontAwesomeIcon icon={['fas', 'angle-down']} />
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.eventKey+""}>
                <Card.Body>
                    <ul className="vaccine-filter">
                        {renderRows()}
                    </ul>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}