import './FullPageLoader.css'
import React from 'react'
import { connect } from 'react-redux'
import { Spinner } from 'react-bootstrap'

function FullPageLoader (props) {

    const { showLoading } = props;

    if(showLoading) {
        return (
            <div className="full-page-loading">
                <Spinner animation="grow"></Spinner>
            </div>
        )
    }else {
        return null
    }
}

function mapStateToProps(state) {
    return {
        showLoading: state.page.showLoading
    };
}

export default connect(mapStateToProps)(FullPageLoader)