import './CalendarCard.css'
import React from 'react'
import DownloadButton from '../downloadButton/DownloadButton'
import { Container } from 'react-bootstrap'

export default props => {

    return(
        <a href={props.file} target="_blank"> 
                <div className={`calendar-card-bg ${props.type}`}>
                    <div className="calendar-card-bg-t">
                        <Container fluid>
                            <h3>{props.title}</h3>
                            <p>{props.description}</p>
                            <DownloadButton file={props.file} text="Download"></DownloadButton>
                        </Container>
                    </div>
                </div>
            </a>
    )
}