import './GenericNav.css'
import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { connect } from 'react-redux'
import { changeActivePage, showLoading } from '../../store/actions/pageActions'

function GenericNav(props) {

    function isDefaultTemplate() {
        const { activePage } = props;
        if('home' === activePage) {
          return false;
        } else {
          return true;
        }
    }
    
    function change(menu) {
        if(props.activePage != menu) {
            props.changeMenu(menu)
            props.showLoading()
        }
    }

    return(
        <Navbar collapseOnSelect expand="lg" className={isDefaultTemplate(props) ? '' : 'nav-bar-transparent'}>
            <Container fluid>
                <Navbar.Brand href={props.urlDefault}>
                    <img className="logotipo" src={props.logo}></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                        <Nav>
                            <Nav.Link active={props.activePage === 'home' ? true : false } href="#/home" onClick={e => change('home')}>Home</Nav.Link>
                            <Nav.Link active={props.activePage === 'vacinas' ? true : false } href="#/vacinas" onClick={e => change('vacinas')}>Vacinas</Nav.Link>
                            <Nav.Link active={props.activePage === 'pacotes' ? true : false } href="#pacotes" onClick={e => change('pacotes')}>Pacotes</Nav.Link>
                            <Nav.Link active={props.activePage === 'calendarios' ? true : false } href="#calendarios" onClick={e => change('calendarios')}>Calendários</Nav.Link>
                            <Nav.Link active={props.activePage === 'querovacinar' ? true : false } href="#/querovacinar" onClick={e => change('querovacinar')}>QUERO VACINAR</Nav.Link>
                        </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}


function mapStateToProps(state) {
    return {
        activePage: state.page.activePage,
        showLoading: state.page.showLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        showLoading() {
            const action = showLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GenericNav)