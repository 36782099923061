import { createStore, combineReducers } from 'redux'
import pageReducer from './reducers/pageReducer'

const reducers = combineReducers({
    page: pageReducer
})

function storeConfig() {
    return createStore(reducers)
}

export default storeConfig


