import './Footer.css'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import logotipo_white from '../../images/logo_novo_final_w.png'
import classic_logo_white from '../../images/classic_logo_white.png'

export default props => {
    if(props.render) {
        return (
            <footer className="footer">
                <Container fluid className="footer-content">
                    <Row>
                        <Col sm={4}>
                            <img className="logotipo logotipo-white" src={logotipo_white}></img>
                            <p>A CDAI Imuniza se preocupa com a saúde e bem-estar de todos. 
                            Pensando nisso, disponibilizamos as mais diversas vacinas para 
                            proteger a saúde de sua família.</p>
                        </Col>
                        <Col sm={8}>
                            {/* <a href="https://www.cdai.com.br/">
                                <img className="classic_logo" src={classic_logo_white}></img>
                            </a> */}
                        </Col>
                    </Row>
                </Container>
            </footer>
        )
    } else {
        return false
    }
}

