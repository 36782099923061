import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhoneAlt, faMapMarkerAlt, faEnvelope, faSyringe, 
         faThumbsUp, faAngleDown, faAngleUp, faAngleLeft, 
         faCalendarCheck, faBoxOpen, faQuestion, faHandsHelping,
         faCheckCircle, faCheck, faAngleRight, faSearch, faTimes,
         faWalking } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faWaze} from '@fortawesome/free-brands-svg-icons';

library.add(
  faPhoneAlt,
  faMapMarkerAlt,
  faEnvelope,
  faWhatsapp,
  faSyringe,
  faThumbsUp,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faCalendarCheck,
  faBoxOpen,
  faQuestion,
  faWaze,
  faHandsHelping,
  faCheckCircle,
  faCheck,
  faAngleRight,
  faSearch,
  faTimes,
  faWalking
);