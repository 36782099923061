import './Header.css'
import React from 'react'
import logotipo from '../../images/logo_novo_final.png'
import GenericNav from '../nav/GenericNav'

export default props => {
    return (
        <GenericNav logo={logotipo} urlDefault="https://www.cdai-imuniza.com.br"></GenericNav>
    )
}

