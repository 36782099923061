import './VaccineModal.css'
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux'
import { changeActivePage, showLoading } from '../../store/actions/pageActions'
import Label from '../label/Label';

function VaccineModal (props) {

    function change(menu) {
        if(props.activePage != menu) {
            props.changeMenu(menu)
            props.showLoading()
        }
    }

    function renderRibbon(available) {
        if(!available){
            return(
                <Label label="Indisponível"></Label>
            )
        }        
    }

    return (
        <Modal {...props} size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={props.show} onHide={e => props.handleClose()}>
            <Modal.Header closeButton>
            <Modal.Title>{props.vaccine.nome}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.vaccine.descricao}
                <div className="vaccineInfos">
                    <Row>
                        <Col>
                            <h3>Dosagem:</h3>
                            {props.vaccine.dosagem}
                        </Col>
                        <Col>
                            <h3>Proteção:</h3>
                            {props.vaccine.protecao}
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a href="#/pacotes" onClick={e => change('pacotes')}>
                    <div className="modalButton">
                        <FontAwesomeIcon icon={['fas', 'box-open']} />
                        <p>PACOTES DE VACINAS</p>
                    </div>
                </a>
                <a href="#/querovacinar" onClick={e => change('querovacinar')}>
                    <div className="modalButton">
                        <FontAwesomeIcon icon={['fas', 'thumbs-up']} />
                        <p>QUERO VACINAR</p>
                    </div>
                </a>
            </Modal.Footer>
            {renderRibbon(props.vaccine.disponivel)}
        </Modal>
    )
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        showLoading() {
            const action = showLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VaccineModal)