import './Calendarios.css'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { changeActivePage, hideLoading } from '../../store/actions/pageActions'
import PageTitle from '../../components/pageTitle/PageTitle'
import CalendarCard from '../../components/calendarCard/CalendarCard'
import FloatButton from '../../components/floatButton/FloatButton';

class Calendarios extends Component {

    constructor(props) {
        super(props)
        props.changeMenu('calendarios')
    }

    componentDidMount() {
        window.scrollTo({ top: 0 });
        setTimeout(() => {
            this.props.hideLoading()
        },1000)
    }

    render() {
        return(
            <div className="calendarios">
                <PageTitle title="Calendários"></PageTitle>
                <Row noGutters={true}>
                    <Col xl={6}>
                        <CalendarCard title="Prematuros" 
                                      description="Tudo para proteger desde o início." 
                                      type="newborn"
                                      file="https://sbim.org.br/images/calendarios/calend-sbim-prematuro.pdf"></CalendarCard>
                    </Col >
                    <Col xl={6}>
                        <CalendarCard title="Crianças - 0 a 10 anos" 
                                      description="Proteção do nascimento à pré-adolescência." 
                                      type="kid"
                                      file="https://sbim.org.br/images/calendarios/calend-sbim-crianca.pdf"></CalendarCard>
                    </Col>
                    <Col xl={6}>
                        <CalendarCard title="Adolescentes - 10 a 19 anos" 
                                      description="Proteção para toda juventude." 
                                      type="teenager"
                                      file="https://sbim.org.br/images/calendarios/calend-sbim-adolescente.pdf"></CalendarCard>
                    </Col>
                    <Col xl={6}>
                        <CalendarCard title="Gestantes" 
                                      description="Protegendo o momento mais importante." 
                                      type="pregnant"
                                      file="https://sbim.org.br/images/calendarios/calend-sbim-gestante.pdf"></CalendarCard>
                    </Col>       
                    <Col xl={6}>
                        <CalendarCard title="Adultos - 20 a 59 anos" 
                                      description="A vida adulta também precisa de proteção." 
                                      type="adult"
                                      file="https://sbim.org.br/images/calendarios/calend-sbim-adulto.pdf"></CalendarCard>
                    </Col>       
                    <Col xl={6}>
                        <CalendarCard title="Idosos - mais de 60 anos" 
                                      description="A vida adulta também precisa de proteção." 
                                      type="old"
                                      file="https://sbim.org.br/images/calendarios/calend-sbim-idoso.pdf"></CalendarCard>
                    </Col>     
                    <Col xl={6}>
                        <CalendarCard title="Ocupacional" 
                                      description="Seu dia-a-dia profissional precisa de proteção." 
                                      type="occupational"
                                      file="https://sbim.org.br/images/calendarios/calend-sbim-ocupacional.pdf"></CalendarCard>
                    </Col>           
                    <Col xl={6}>
                        <CalendarCard title="Único" 
                                      description="Protegendo do nascimento à terceira idade." 
                                      type="all"
                                      file="https://sbim.org.br/images/calendarios/calend-sbim-0-100.pdf"></CalendarCard>
                    </Col>                                                                                       
                </Row>
                <FloatButton iconGroup="fas" iconName="phone-alt" description="Contatos" target="querovacinar" param={4}></FloatButton>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        hideLoading() {
            const action = hideLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Calendarios)