import './Contact.css'
import React from 'react'
import ImageIcon from '../imageIcon/ImageIcon'

export default props => {
    return(
        <a href={props.href} target="_blank">
            <div className="contact">
                <ImageIcon iconGroup={props.iconGroup} iconName={props.iconName}></ImageIcon>
                <div className="contact-description">
                    <h4>{props.title}</h4>
                    <h4>{props.number}</h4>
                    <span>{props.message}</span>
                </div>
            </div>
        </a>
    )
}