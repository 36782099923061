import './PageTitle.css'
import React from 'react'
import { Container } from 'react-bootstrap'

export default props => {
    return (
        <div className="pageTitle">
            <Container fluid>
                <h2>{props.title}</h2>
            </Container>
        </div>
    )
}