import './DefaultButton.css'
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default props => {
    return(
        <a onClick={e => props.click(props.param)}>
            <div className="defaultButton" >
                <p>{props.description}</p>
            </div>
        </a>
    )
}