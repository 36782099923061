import './DefaultIcon.css'
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default props => {

    function renderColor() {
        switch (props.color) {
            case 'red':
                return 'defaultIcon red'
                break;
            case 'blue':
                return 'defaultIcon blue'
                break;
            case 'gray':
                return 'defaultIcon gray'
                break;    
            default:
                return 'defaultIcon red'
                break;
        }
    }

    return (
        <div className={renderColor()}>
            <FontAwesomeIcon icon={[props.iconGroup, props.iconName]} />
        </div>
    )
}