import './LinkButton.css'
import React from 'react'
import { connect } from 'react-redux'
import { changeActivePage, showLoading } from '../../store/actions/pageActions'

function LinkButton (props) {

    function change(menu) {
        if(props.activePage != menu) {
            props.changeMenu(menu)
            props.showLoading()
        }
    }

    return (
        <a className="linkButton" href={`#/${props.target}`} onClick={e => change(props.target)}>
            {props.description}
        </a>
    )
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        showLoading() {
            const action = showLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LinkButton)