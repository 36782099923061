import './FloatButton.css'
import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { changeActiveStep } from '../../store/actions/pageActions'

function FloatButton (props) {

    if(props.target) {
        return(
            <a onClick={e => props.changeActiveStep(props.param)} href={`#/${props.target}`}>
                <div className="floatButton">
                    <div className="floatButtonIcon">
                        <FontAwesomeIcon icon={[props.iconGroup, props.iconName]} />
                    </div>
                    <div className="floatButtonDescription">
                        <p>{props.description}</p>
                    </div>
                </div>
            </a>
        )
    } else {
        return(
            <a onClick={e => props.click(props.param)}>
                <div className="floatButton">
                    <div className="floatButtonIcon">
                        <FontAwesomeIcon icon={[props.iconGroup, props.iconName]} />
                    </div>
                    <div className="floatButtonDescription">
                        <p>{props.description}</p>
                    </div>
                </div>
            </a>
        )
    }
}

function mapStateToProps(state) {
    return {
        activeStep: state.page.activeStep
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeActiveStep(activeStep) {
            const action = changeActiveStep(activeStep)
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FloatButton)
