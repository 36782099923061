import './PackageCard.css'
import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import Label from '../label/Label'

export default props => {

    function renderRibbon(available) {
        if(!available){
            return(
                <Label label="Indisponível"></Label>
            )
        }        
    }

    function renderList(itens) {
        var i = 0;
        return itens.map(item => (
            <li key={i++}>{item}</li>
        ))
    }

    return (
        <Card className="package-card" onClick={e => props.handleChoose(props.package)}> 
            <Card.Header className="package-card-header">
                <h5>{props.package.nome}</h5>
                <p>{props.package.descricao}</p>
            </Card.Header>
            <Card.Body>
                <Card.Title>Vacinas inclusas:</Card.Title>
                <ul>
                    {renderList(props.package.vacinas)}
                </ul>
            </Card.Body>
            <Card.Footer>
                Detalhes
            </Card.Footer>
            {renderRibbon(props.package.disponivel)}
        </Card>
    )
}