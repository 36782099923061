import './MainMessage.css'
import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ImageIcon from '../imageIcon/ImageIcon'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default props => {
    return (
        <div className="messageContent">
            <Row>
                <Col xl={7}>
                    <h1>{props.title}</h1>
                </Col>
            </Row>
            <Row>
                <Col xl={7}>
                    <h3>{props.message}</h3>
                </Col>
            </Row>
            <Row>
                <Col xl={7}>
                    <h5>{props.message2}</h5>
                    <h6>
                        <FontAwesomeIcon icon={[props.iconGroup, props.iconName]} />
                        <a href={props.target} target="_blank">
                            {props.phone}
                        </a>
                    </h6>
                    <h6>
                        <FontAwesomeIcon icon={[props.iconGroup2, props.iconName2]} />    
                        <a href={props.target2} target="_blank">
                            {props.phone2}
                        </a>
                    </h6>
                </Col>
            </Row>
        </div>
    )    
}