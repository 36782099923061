import { SELECTED_MENU, SHOW_LOADING, HIDE_LOADING, ACTIVE_STEP } from './actionsType'

//Action Creator
export function changeActivePage(pageName) {
    return {
        type: SELECTED_MENU,
        payload: pageName
    }
}

export function showLoading(show) {
    return {
        type: SHOW_LOADING,
    }
}

export function hideLoading(hide) {
    return {
        type: HIDE_LOADING,
    }
}

export function changeActiveStep(activeStep) {
    return {
        type: ACTIVE_STEP,
        payload: activeStep
    }
}
