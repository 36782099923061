import './App.css';
import React, { Component } from 'react'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Routes from './pages/Routes'
import FullPageLoader from './components/fullPageLoader/FullPageLoader';
import { connect } from 'react-redux'
import $ from 'jquery';

//Conteúdo da página provisória
import Provisory from './pages/provisory/Provisory'
import { Container } from 'react-bootstrap';

class App extends Component {

  constructor(props) {
    super(props)
    this.isDefaultTemplate = this.isDefaultTemplate.bind(this)
    this.renderPadding = this.renderPadding.bind(this)
  }

  componentDidMount() {
    $("#rootLoading").fadeOut();
  }

  isDefaultTemplate() {
    const { activePage } = this.props;
    if('home' === activePage || 'querovacinar' === activePage) {
      return false;
    } else {
      return true;
    }
  }
  
  renderPadding() {
    const { activePage } = this.props;
    if('home' === activePage) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <div className={this.renderPadding() ? 'wrapContainer container-padding' : 'wrapContainer'}>
          <FullPageLoader></FullPageLoader>
          <Header></Header>
          <Routes></Routes>
          <Footer render={this.isDefaultTemplate()}></Footer>
      </div>
      // <div className="wrapContainer container-padding">
      //     <Container fluid>
      //         <Header></Header>
      //         <Provisory></Provisory>
      //     </Container>
      //     <Footer render={true}></Footer>
      // </div>
    );
  }

}

function mapStateToProps(state) {
  return {
      activePage: state.page.activePage,
      showLoading: state.page.showLoading
  };
}

export default connect(    
  mapStateToProps)
(App)
