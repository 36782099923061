import './InputText.css'
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default props => {
    return(
        <div className="inputText">
            <FontAwesomeIcon className="search" icon={['fas', 'search']} />
            <input placeholder={props.placeholder} onChange={(e) => props.handleChange(e.target.value)} value={props.description}></input>
        </div>
    )
}