import './Home.css'
import React, { Component }  from 'react'
import MainMessage from '../../components/mainMassage/MainMessage'
import CardButton from '../../components/cardButton/CardButton'
import { Row, Col, Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import { changeActivePage, hideLoading } from '../../store/actions/pageActions'
import $ from 'jquery';

class Home extends Component {

    constructor(props) {
        super(props)
        props.changeMenu('home')
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.hideLoading()
        },500)
    }
    
    render() {
        return (
            <div>
                <div className="home-bg">
                    <div className="home-bg-t">
                        <Container fluid>
                            <div className="message-content">
                                <MainMessage type={1}
                                            title="Proteja seus melhores momentos" 
                                            message="A CDAI Imuniza se preocupa com a saúde e bem-estar de todos.
                                                    Pensando nisso, disponibilizamos as mais diversas vacinas para proteger a saúde de sua família."
                                            message2="Ligue para nós e proteja sua família! "
                                            target="tel:08004941032"
                                            phone=" 0800-494-1032"
                                            iconGroup="fas" 
                                            iconName="phone-alt" 
                                            target2="https://api.whatsapp.com/send?phone=5561981850545"
                                            phone2=" (61) 98185-0545"
                                            iconGroup2="fab" 
                                            iconName2="whatsapp">
                                </MainMessage>
                            </div>
                        </Container>
                    </div>
                </div>
    
                <div className="link-content">
                    <Container fluid>
                        <Row className="justify-content-xl-end">
                            <Col md="auto" xs={6}>
                                <CardButton title="VACINAS" message="Tudo que você precisa saber sobre vacinas" iconGroup="fas" iconName="syringe" target="vacinas"></CardButton>
                            </Col>
                            <Col md="auto" xs={6}>
                                <CardButton title="QUERO VACINAR!" message="Todos os passos para você se proteger."  iconGroup="fas" iconName="thumbs-up" target="querovacinar"></CardButton>
                            </Col>
                        </Row>
                    </Container>
                </div >
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        hideLoading() {
            const action = hideLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home)