import { SELECTED_MENU, SHOW_LOADING, HIDE_LOADING, ACTIVE_STEP  } from '../actions/actionsType'

const initialState = {
    activePage: 'home',
    showLoading: false,
    activeStep: 0
}

export default function(state = initialState, action) {

    switch(action.type) {
        case SELECTED_MENU: 
            return {
                ...state,
                activePage: action.payload
            }
        case SHOW_LOADING:
            return {
                ...state,
                showLoading: true
            } 
        case HIDE_LOADING:
            return {
                ...state,
                showLoading: false
            } 
        case ACTIVE_STEP:
             return {
                ...state,
                activeStep: action.payload
             }    
            
        default:
            return state

    }

}

