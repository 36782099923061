import React from 'react'
import { Route, Redirect, HashRouter } from 'react-router-dom'
import Home from './home/Home'
import Provisory from './provisory/Provisory'
import Pacotes from './pacotes/Pacotes'
import Calendarios from './calendarios/Calendarios'
import QueroVacinar from './querovacinar/QueroVacinar'
import Vacinas from './vacinas/Vacinas'

export default props => {
    return (
        <HashRouter> 
            <Route path="/home" component={Home}></Route>
            <Route path="/provisory" component={Provisory}></Route>
            <Route path="/vacinas" component={Vacinas}></Route>
            <Route path="/pacotes" component={Pacotes}></Route>
            <Route path="/calendarios" component={Calendarios}></Route>
            <Route path="/querovacinar" component={QueroVacinar}></Route>
            <Redirect from="*" to="/home"></Redirect>
        </HashRouter>
    )
}
