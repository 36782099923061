import './QueroVacinar.css'
import React, { Component } from 'react'
import $ from 'jquery';
import PageTitle from '../../components/pageTitle/PageTitle'
import DefaultIcon from '../../components/defaultIcon/DefaultIcon';
import DefaultButton from '../../components/defaultButton/DefaultButton';
import FloatButton from '../../components/floatButton/FloatButton';
import LinkButton from '../../components/linkButton/LinkButton';
import BottomContact from '../../components/bottomContact/BottomContact';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux'
import { changeActivePage, changeActiveStep, hideLoading } from '../../store/actions/pageActions'

const htmlBody = $('html, body')
const initialState = {
    index: 0,
    stepDescription: ""
}

class QueroVacinar extends Component {

    state = { ...initialState }

    constructor(props) {
        super(props)
        this.handleSelect = this.handleSelect.bind(this)
        props.changeMenu('querovacinar')
        console.log(props.activeStep)
    }

    componentDidMount() {
        htmlBody.addClass('overflowHidden')
        window.scrollTo({ top: 0 });
        setTimeout(() => {
            this.props.hideLoading()
        },500)
        this.setState({ index: this.props.activeStep })
    }

    componentWillUnmount() {
        htmlBody.removeClass('overflowHidden')
    }

    handleSelect(selectedIndex, e) {
        let description;
        switch (selectedIndex) {
            case 1:
                description = "- Primeiro passo"
                break;
            case 2:
                description = "- Segundo passo"
                break;                
            case 3:
                description = "- Terceiro passo"
                break;
            case 4:
                description = "- Último passo"
                break;                                                
            default:
                description = ""
                break;
        }

        this.setState({ index: selectedIndex, stepDescription: description })
        this.props.changeActiveStep(selectedIndex)
    }

    renderContactButtons() {
        if((this.state.index === 4 )) {
            return (
                <div className="contactButtons">
                    <Container fluid>
                        <Row>
                            <Col>
                                <BottomContact target="tel:08004941032" 
                                               iconGroup="fas" 
                                               iconName="phone-alt" 
                                               description="Ligar" 
                                               additionalDescription="0800-494-1032"></BottomContact>
                            </Col>
                            <Col>
                                <BottomContact target="https://api.whatsapp.com/send?phone=5561981850545" 
                                               iconGroup="fab" 
                                               iconName="whatsapp" 
                                               description="WhatsApp" 
                                               additionalDescription="(61) 98185-0545"></BottomContact>
                            </Col>
                            <Col>
                                <BottomContact target="https://www.waze.com/pt-BR/livemap?zoom=17&lat=-15.81456&lon=-47.91323" 
                                               iconGroup="fab" 
                                               iconName="waze" 
                                               description="Waze"
                                               additionalDescription="Descubra a melhor rota!"></BottomContact>
                            </Col>
                            <Col>
                                <BottomContact target="https://goo.gl/maps/k1hE3RRzh6T2" 
                                               iconGroup="fas" 
                                               iconName="map-marker-alt" 
                                               description="Maps"
                                               additionalDescription="É fácil nos encontrar"></BottomContact>
                            </Col>
                            <Col>
                                <BottomContact target="mailto:vacinas@cdai.com.br" 
                                               iconGroup="fas" 
                                               iconName="envelope" 
                                               description="E-mail"
                                               additionalDescription="vacinas@cdai.com.br"></BottomContact>
                            </Col>       
                            <Col>
                                <BottomContact target="https://www.cdai.com.br/tour/" 
                                               iconGroup="fas" 
                                               iconName="walking" 
                                               description="Tour"
                                               additionalDescription="Faça um tour virtual!"></BottomContact>
                            </Col>                       
                        </Row>
                    </Container>
                </div>
            )
        }
    }

    renderFloatButton() {
        if(this.state.index != 4) {
            return(
                <FloatButton iconGroup="fas" iconName="phone-alt" description="Contatos" click={this.handleSelect} param={4}></FloatButton>
            )
        }
    }

    render() {
        return (
            <div>
                <PageTitle title={`Quero Vacinar ${this.state.stepDescription}`}></PageTitle>
                <div className="quero-vacinar">
                    <Carousel nextLabel="Próximo" 
                              prevLabel="Anterior" 
                              indicators={false} 
                              fade={true} 
                              touch={false}
                              activeIndex={this.state.index} 
                              onSelect={this.handleSelect} 
                              interval={null} 
                              controls={(this.state.index === 0 || this.state.index === 4)  ? false : true}>
                        <Carousel.Item>
                            <Carousel.Caption>
                                <div className="quero-vacinar-content">
                                    <DefaultIcon iconGroup="fas" iconName="question"></DefaultIcon>
                                    <h2>Precisa de vacinas e não sabe por onde começar?</h2>
                                    <h2>Não tem problema, a gente te ajuda!</h2>
                                    <DefaultButton description="Comece por aqui!" param={1} click={this.handleSelect}></DefaultButton>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Carousel.Caption>
                                <div className="quero-vacinar-content">
                                    <DefaultIcon iconGroup="fas" iconName="calendar-check"></DefaultIcon>
                                    <h2>Seu calendário está em dia?</h2>
                                    <h3>Além de fornecer orientações específicas para todas as etapas de nossas vidas, 
                                        os calendários nos ajudam a manter nossas vacinas sempre em dia.</h3>
                                    <LinkButton target="calendarios" description="Calendários"></LinkButton>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Carousel.Caption>
                                <div className="quero-vacinar-content">
                                    <DefaultIcon iconGroup="fas" iconName="syringe"></DefaultIcon>
                                    <h2>Já sabe qual vacina precisa?</h2>
                                    <h3>É muito importante saber os detalhes da vacina, quantidade de doses, proteções e
                                        qual etapa da vida devemos nos vacinar, a CDAI Imuniza te ajuda nessa etapa!</h3>
                                    <LinkButton target="vacinas" description="Vacinas"></LinkButton>                                    
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Carousel.Caption>
                                <div className="quero-vacinar-content">
                                    <DefaultIcon iconGroup="fas" iconName="box-open"></DefaultIcon>
                                    <h2>Precisa de mais de uma vacina?</h2>
                                    <h3>Para facilitar, a CDAI Imuniza montou diversos pacotes com vacinas essenciais,
                                        com certeza, um deles é o ideal para você e sua família!</h3>
                                    <LinkButton target="pacotes" description="Pacotes"></LinkButton>                                           
                                </div>                              
                            </Carousel.Caption>
                        </Carousel.Item>          
                        <Carousel.Item>
                            <Carousel.Caption>
                                <div className="quero-vacinar-content">
                                    <DefaultIcon iconGroup="fas" iconName="hands-helping"></DefaultIcon>
                                    <h2>Venha nos conhecer!</h2>
                                    <h3>Já sabe tudo que precisa? Ainda tem dúvidas? Fique tranquilo! Estamos
                                        aqui para ajudar, pode nos ligar, enviar mensagem ou vir direto para nos
                                        conhecer e se proteger!</h3>
                                    <div className="quero-vacinar-content-paragraph">
                                        <p>Estamos disponíveis de segunda à sábado das 08h00 às 18h00</p>
                                        <p>Centro Clínico Via Brasil - SEPS 710/910 - Torre B - Sala 334/336</p>                                        
                                    </div>
                                    <DefaultButton description="Voltar" param={3} click={this.handleSelect}></DefaultButton>    
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>                                          
                    </Carousel>

                </div>
                {this.renderContactButtons()}
                {this.renderFloatButton()}
            </div>
        )   
    }
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage,
        activeStep: state.page.activeStep
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        hideLoading() {
            const action = hideLoading()
            dispatch(action)
        },
        changeActiveStep(activeStep) {
            const action = changeActiveStep(activeStep)
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QueroVacinar)