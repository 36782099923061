import './CardButton.css'
import React from 'react'
import ImageIcon from '../imageIcon/ImageIcon'
import { connect } from 'react-redux'
import { changeActivePage, showLoading } from '../../store/actions/pageActions'

function CardButton (props) {

    function change(menu) {
        if(props.activePage !== menu) {
            props.changeMenu(menu)
            props.showLoading()
        }
    }

    return (
        <a href={`#/${props.target}`} onClick={e => change(props.target)}>
            <div className="cardButton">
                <div className="cardButton-icon">
                    <ImageIcon iconGroup={props.iconGroup} iconName={props.iconName}></ImageIcon>
                </div>
                <div className="cardButton-description">
                    <div className="cardButton-icon-mb">
                        <ImageIcon iconGroup={props.iconGroup} iconName={props.iconName}></ImageIcon>
                    </div>
                    <h4>{props.title}</h4>
                    <h5>{props.message}</h5>
                </div>
            </div>
        </a>
    )
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage,
        showLoading: state.page.showLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        showLoading() {
            const action = showLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardButton)