import './Provisory.css'
import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Contact from '../../components/contact/Contact'
import vacinas from '../../resources/vacinas.json'
import { Container } from 'react-bootstrap'

function Provisory (props)  {

    const renderRows = () => {
        const list = vacinas || []
        return list.map(vacina => (
            <Col sm={6} key={vacina.id}>
                <p>{vacina.nome}</p>
            </Col>
        ))
    }

    return (
        <Container fluid>
            <div className="content">
                <p>A CDAI VACINAS se preocupa com a saúde e bem-estar de todos.</p>
                <p>Pensando nisso, disponibilizamos as mais diversas vacinas para proteger a saúde de sua família.</p>

                <div className="vacinas-content">
                    <h2>VACINAS DISPONÍVEIS:</h2>
                    <div>
                        <Row>                       
                            {renderRows()}
                        </Row>
                    </div>
                </div>

                <div className="vacinas-content">
                    <h2>ENTRE EM CONTATO:</h2>
                    <div>
                        <Row>
                            <Col xl={6}>
                                <Contact href="tel:+556132085177"
                                        iconGroup="fas" 
                                        iconName="phone-alt" 
                                        title="Ainda está com dúvidas? Ligue para gente!"
                                        number="(61) 3208-5177"
                                        message="Estamos disponíveis de segunda à sabado das 08h00 às 18h00."></Contact>
                                <Contact href="https://api.whatsapp.com/send?phone=5561981850545"
                                        iconGroup="fab" 
                                        iconName="whatsapp"
                                        title="Prefere conversar pelo WhatsApp? Pode nos chamar!"
                                        number="(61) 98185-0545"
                                        message="Estamos disponíveis de segunda à sabado das 08h00 às 18h00."></Contact>
                            </Col>
                            <Col xl={6}>
                                <Contact href="https://www.waze.com/pt-BR/livemap?zoom=17&lat=-15.81456&lon=-47.91323"
                                        iconGroup="fas" 
                                        iconName="map-marker-alt"
                                        title="Já sabe qual vacina precisa? Ainda tem dúvidas?"
                                        number="Nos encontrar é muito fácil!"
                                        message="Centro Clínico Via Brasil - SEPS 710/910 - Torre B - Sala 334/336."></Contact>
                                <Contact href="mailto:vacinas@cdai.com.br"
                                        iconGroup="fas" 
                                        iconName="envelope"
                                        title="Sugestões ou dúvidas?"
                                        message="Nos envie um e-mail, nossa equipe irá responder o mais breve possível!"></Contact>
                            </Col>                        
                        </Row>
                    </div>
                </div>
            </div>      

        </Container>
    )
}

export default Provisory