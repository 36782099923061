import './Vacinas.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { changeActivePage, hideLoading, showLoading } from '../../store/actions/pageActions'
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap'
import { matchSorter } from 'match-sorter'
import vacinas from '../../resources/vacinas.json'
import grupos from '../../resources/grupos.json'
import AccordionGroup from '../../components/accordionGroup/AccordionGroup'
import VaccineCard from '../../components/vaccineCard/VaccineCard'
import InputText from '../../components/inputText/InputText'
import DefaultButton from '../../components/defaultButton/DefaultButton'
import PageTitle from '../../components/pageTitle/PageTitle'
import VaccineModal from '../../components/vaccineModal/VaccineModal'
import DefaultIcon from '../../components/defaultIcon/DefaultIcon'
import FloatButton from '../../components/floatButton/FloatButton';
import $ from 'jquery';

const initialState = {
    selectedGroup: "Todas as Vacinas",
    show: false,
    selectedVaccine: { nome: "", descricao: "", dosagem: "", protecao: "" },
    vacinasList: vacinas,
    filterDescription: ""
}

class Vacinas extends Component {

    state = { ...initialState }

    constructor(props) {
        super(props)
        this.handleSelected = this.handleSelected.bind(this)
        this.renderVacinas = this.renderVacinas.bind(this)
        this.clearFilter = this.clearFilter.bind(this)
        this.closeMainAccordion = this.closeMainAccordion.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleChoose = this.handleChoose.bind(this)
        this.handleChange = this.handleChange.bind(this)
        props.changeMenu('vacinas')
    }

    componentDidMount() {
        window.scrollTo({ top: 0 });
        setTimeout(() => {
            this.props.hideLoading()
        },500)
    }

    handleSelected(e, groupName) {
        var listItens = $('.card-body ul li')
        listItens.removeClass('listActive')
        e.target.classList.toggle('listActive')
        var filter = e.target.lastChild.textContent;

        const result = matchSorter(vacinas, filter, {keys: ['grupos']})
        this.setState({ filterDescription: "", selectedGroup: `${groupName} - ${filter}`, vacinasList: result })
        this.closeMainAccordion()
        this.renderEmpty()
    }

    closeMainAccordion() {
        $('.mainAccordion').each((index, element) => {
            element.click()
        })
    }

    clearFilter() {
        var listItens = $('.card-body ul li')
        listItens.removeClass('listActive')
        this.setState({ filterDescription: "", selectedGroup: 'Todas as Vacinas', vacinasList: vacinas }) 
    }

    renderVacinas() {
        const list = this.state.vacinasList || []
        return list.map(vacina => (
            <Col xs={6} sm={3} key={vacina.id}>
                <VaccineCard handleChoose={this.handleChoose} vaccine={vacina}></VaccineCard>
            </Col>
        ))
    }

    renderEmpty() {
        if(this.state.vacinasList.length === 0) {
            return (
                <div className="empty">
                    <DefaultIcon iconGroup="fas" iconName="syringe" color="gray"></DefaultIcon>
                    <h2>Nenhuma vacina encontrada!</h2>
                </div>
            )
        }
    }

    handleChoose(vaccine) {
        this.setState({ selectedVaccine: vaccine})
        this.handleShow()
    }

    handleClose() {
        this.setState({ show: false })
    } 

    handleShow () {
        this.setState({ show: true })
    }

    handleChange(e) {
        if('' === e) {
            this.setState({ filterDescription: "", selectedGroup: 'Todas as Vacinas', vacinasList: vacinas })            
        } else {
            const result = matchSorter(vacinas, e, {keys: ['nome']})
            this.setState({ filterDescription: e, selectedGroup: e, vacinasList: result })
        }
        this.renderEmpty()
    }

    render() {
        return(
            <Container fluid>
                <PageTitle title={this.state.selectedGroup}></PageTitle>
                <div className="vacinas">
                    <div className="leftMenuContent">
                        <div className="filtersMobile">
                            <div className="inputMobile">
                                <InputText placeholder="Busque pelo nome da vacina" handleChange={this.handleChange} description={this.state.filterDescription}></InputText>
                            </div>
                            <Accordion>
                                <Card>
                                    <Accordion.Toggle className="mainAccordion" as={Card.Header} eventKey="0">
                                        Filtre por grupo
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <AccordionGroup initIndex={0} handleSelected={this.handleSelected} accordions={grupos}></AccordionGroup>                                
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <DefaultButton description="Limpar filtros" click={this.clearFilter}></DefaultButton>
                        </div>

                        <div className="filtersDesktop">
                            <InputText placeholder="Busque pelo nome da vacina" handleChange={this.handleChange} description={this.state.filterDescription}></InputText>
                            <AccordionGroup initIndex={6} handleSelected={this.handleSelected} description="Filtre por grupo" accordions={grupos}></AccordionGroup>
                            <DefaultButton description="Limpar filtros" click={this.clearFilter}></DefaultButton>
                        </div>
                    </div>
                    <div className="vacinasContent">
                        <Row>
                            {this.renderVacinas()}
                            {this.renderEmpty()}
                        </Row>
                    </div>
                </div>

                <VaccineModal show={this.state.show} handleClose={this.handleClose} handleShow={this.handleShow} vaccine={this.state.selectedVaccine}></VaccineModal>
                <FloatButton iconGroup="fas" iconName="phone-alt" description="Contatos" target="querovacinar" param={4}></FloatButton>
            </Container>   
            
        )
    }
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        hideLoading() {
            const action = hideLoading()
            dispatch(action)
        },
        showLoading() {
            const action = showLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Vacinas)