import './VaccineCard.css'
import React from 'react'
import { Card } from 'react-bootstrap'
import Label from '../label/Label'

export default props => {

    function renderRibbon(available) {
        if(!available){
            return(
                <Label label="Indisponível"></Label>
            )
        }        
    }

    return (
        <div>
            <Card className="text-center vaccineCard" onClick={e => props.handleChoose(props.vaccine)}>
                <Card.Header className="vaccineCardHeader">{props.vaccine.nome}</Card.Header>
                <Card.Body>
                    <Card.Title>Dosagem:</Card.Title>
                    <Card.Text>{props.vaccine.dosagem}</Card.Text>
                    <Card.Title>Proteção:</Card.Title>
                    <Card.Text>{props.vaccine.protecao}</Card.Text>
                </Card.Body>
                <Card.Footer>
                    Detalhes
                </Card.Footer>
                {renderRibbon(props.vaccine.disponivel)}
            </Card>
        </div>
    )
}