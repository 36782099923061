import './BottomContact.css'
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default props => {
    return(
        <a href={props.target} target="_blank">
            <div className="bottomContact">
                <FontAwesomeIcon icon={[props.iconGroup, props.iconName]} />
                <p>{props.description}</p>
                <p className="addicionalDescription">{props.additionalDescription}</p>
            </div>
        </a>
    )
}