import './Pacotes.css'
import React, { Component } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { changeActivePage, hideLoading } from '../../store/actions/pageActions'
import { matchSorter } from 'match-sorter'
import pacotes from '../../resources/pacotes.json'
import PageTitle from '../../components/pageTitle/PageTitle'
import InputText from '../../components/inputText/InputText'
import PackageCard from '../../components/packageCard/PackageCard'
import PackageModal from '../../components/packageModal/PackageModal'
import DefaultButton from '../../components/defaultButton/DefaultButton'
import FloatButton from '../../components/floatButton/FloatButton';

const initialState = {
    show: false,
    selectedPackage: {
        nome : "Pacote 2 meses",
        descricao: "Todas as vacinas necessárias até os 2 meses de vida.",
        disponivel: true,
        vacinas: [],
        protecao : []
    },
    packageList: pacotes,
    filterDescription: ""
}

class Pacotes extends Component {

    state = { ...initialState }

    constructor(props) {
        super(props)
        this.renderPacotes = this.renderPacotes.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleShow = this.handleShow.bind(this)
        this.handleChoose = this.handleChoose.bind(this)
        this.clearFilter = this.clearFilter.bind(this)
        props.changeMenu('pacotes')
    }

    componentDidMount() {
        window.scrollTo({ top: 0 });
        setTimeout(() => {
            this.props.hideLoading()
        },500)
    }

    renderPacotes() {
        const list = this.state.packageList
        return list.map(pacote => (
            <Col sm={12} key={pacote.id}>
                <PackageCard handleChoose={this.handleChoose} package={pacote}></PackageCard>
            </Col>
        ))
    }

    handleChange(e) {
        if('' === e) {
            this.setState({ filterDescription: "", packageList: pacotes })            
        } else {
            const result = matchSorter(pacotes, e, {keys: ['vacinas']})
            this.setState({ filterDescription: e, packageList: result })
        }
    }

    handleChoose(pacote) {
        this.setState({ selectedPackage: pacote })
        this.handleShow()
    }

    handleClose() {
        this.setState({ show: false })
    } 

    handleShow () {
        this.setState({ show: true })
    }

    clearFilter() {
        this.setState({ filterDescription: "", packageList: pacotes }) 
    }


    render() {
        return(
            <Container fluid>
                <PageTitle title="Pacotes - Em breve!"></PageTitle>
                {/* <div className="pacotes">
                    <div>
                        <Row>
                            <Col lg={10}>
                                <InputText placeholder="Busque pelo nome da vacina" handleChange={this.handleChange} description={this.state.filterDescription}></InputText>
                            </Col>
                            <Col lg={2}>
                                <DefaultButton description="Limpar" click={this.clearFilter} click={this.clearFilter}></DefaultButton>
                            </Col>
                        </Row>
                    </div>
                    <div className="pacotes-itens">
                        <Row>
                            {this.renderPacotes()}
                        </Row>
                    </div>
                </div>
                
                <PackageModal show={this.state.show} handleClose={this.handleClose} handleShow={this.handleShow} package={this.state.selectedPackage}></PackageModal> */}
            <FloatButton iconGroup="fas" iconName="phone-alt" description="Contatos" target="querovacinar" param={4}></FloatButton>
            </Container>           
        )
    }
}

function mapStateToProps(state) {
    return {
        activePage: state.page.activePage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeMenu(pageName) {
            const action = changeActivePage(pageName)
            dispatch(action)
        },
        hideLoading() {
            const action = hideLoading()
            dispatch(action)
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Pacotes)